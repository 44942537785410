import React from "react";

function BlogTitle(props){
    return (
        <div className="title-transition section valign-wrapper">
            <div className="row valign">
                <div className="col s10 offset-s1 m6 offset-m3 blog-title">
                    <h3 id="small-header" className="hide-on-med-and-up">{props.title}</h3>
                    <h1 id="article-title" className="hide-on-small-only">{props.title}</h1>
                    <span  id="article-date" className="date">{props.date}</span>
                </div>
            </div>
        </div>        
    )
}

export default BlogTitle