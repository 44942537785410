import React, { useEffect } from "react";

function Courses() {

    useEffect(() => {
        document.title = 'Courses'
        window.scrollTo(0, 0)
    }, []);


    return (
        <>
            <div className="container">

                {/* <nav className="nav-extended" style={{paddingTop: "64px"}}>

                    <div className="categories-wrapper af lighten-1">
                        <div className="categories-container pin-top" style={{top: "0px"}}>
                            <ul className="categories db">
                                <li className="k"><a href="#all">All</a></li>
                                <li><a href="#polygon">Polygon</a></li>
                                <li><a href="#bigbang">Big Bang</a></li>
                                <li><a href="#sacred">Sacred Geometry</a></li>
                            </ul>
                        </div>
                    </div>
                </nav> */}
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <div className="row">
                    <div className="col s12 m12 blog-title">
                        <h1 className="text-6xl font-extrabold text-slate-900 tracking-tight dark:text-slate-200">COURSES</h1>
                    </div>     
                </div>

                <br></br>
                <br></br>
                <br></br>
                <br></br>                           
                <div className="row">
                    <div className="col s12 m4">
                        <div className="card all-border-radius">
                            <div className="card-image top-border-radius">
                                <img className="top-border-radius" src="assets/images/thymeleafhtmx.JPG"/>
                                 
                            </div>
                            <div className="card-content">
                                <h4 className="text-2xl font-extrabold">Coming Soon!</h4>
                                <p>Mastering Dynamic Web Development: Harnessing the Power of htmx and Thymeleaf</p>
                            </div>
                            <div className="card-action bottom-border-radius">
                                <a href="#"><span className="tag tag-nextjs">#htmx</span></a>
                                <a href="#"><span className="tag tag-nextjs">#Thymeleaf</span></a>
                                <a href="#"><span className="tag tag-nextjs">#Spring</span></a>
                            </div>
                        </div>
                    </div>
                </div>     
                     
            </div>



            
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>

        </>


    )
}

export default Courses