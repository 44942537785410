import React, { useEffect } from "react";
import { useLoaderData, Link } from "react-router-dom";
import BlogSnippet from "./BlogSnippet";


function Home(props){

    const blogData = useLoaderData();

    // console.log(blogData);
    useEffect(() => {
        document.title = 'Home'
        window.scrollTo(0, 0)
    }, []);   
    
    let blogPostSnippets = blogData.map(topicData =>
        <div key={topicData.body} className="section full-height">
            <div className="row valign">
                <div className="col m6 offset-m3">                    
                    <h2 className="center">
                        <Link to={'/Blog/' + JSON.parse(topicData.body).Items[0].ARTICLE_TYPE} style={{ color: "#039be5" }}>
                            {JSON.parse(topicData.body).Items[0].ARTICLE_TYPE} Blog Posts
                        </Link>
                    </h2>
                </div>
            </div>
            <div className="row valign">
                <div className="col s12 m6 offset-m3 blog-body">
                    <BlogSnippet blogData={topicData} imageLink={props.imageLink} />
                </div>
            </div>

        </div>        
    );       

    return(
        <>
            <div className="section full-height">
                <div className="row valign">
                    <div className="col m6 offset-m3 blog-body">   
                    <br></br>                 
                    <br></br>  
                    <h2 className="text-4xl font-extrabold center">Welcome to MaxDevSkills - Your Pathway to Mastery in Software Engineering!</h2>
                    <br></br>
                    <p className="grey-text text-lighten-1">
                        At MaxDevSkills, we understand the journey of recent graduates transitioning 
                        into seasoned software engineers. Our mission is to empower individuals at every 
                        stage of their career with the tools and knowledge needed to excel in the ever-evolving tech landscape.
                    </p>

                    <p className="grey-text text-lighten-1">
                        Gone are the days of sifting through endless hours of fluff-filled courses. We've 
                        revolutionized learning by offering concise, digestible modules tailored to fit your busy 
                        schedule. No more lengthy commitments or overwhelm. With our approach, you can master existing 
                        skills and conquer new ones without sacrificing your precious time.
                    </p>

                    <p className="grey-text text-lighten-1"> 
                        Our carefully curated courses are designed by industry experts to provide you with practical, 
                        real-world insights that you can apply immediately. Whether you're a recent graduate looking to solidify 
                        your foundation or a seasoned veteran eager to stay ahead of the curve, MaxDevSkills has something for you.
                    </p>

                    <p className="grey-text text-lighten-1">
                        Say goodbye to the frustration of long, drawn-out courses. With MaxDevSkills, you can learn a 
                        new skill over the weekend and hit the ground running on Monday. It's time to take control 
                        of your professional development journey and unlock your full potential with MaxDevSkills. 
                        Join us today and embark on your path to mastery in software engineering.
                    </p>
                    </div>
                </div>
            </div>          

            {blogPostSnippets}
        </>  
    )
}

export default Home;