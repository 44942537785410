import React from "react";
import { Link } from "react-router-dom";

function AuthorSection(props){
    return (
        
        <div className="col s10 offset-s1 m6 offset-m3 blog-author">
            <br/>
            <br/>
            <br/>            
            <div className="divider"></div>

            <Link to="/About/1">
                <div className="avatar-wrapper">
                    <div className="avatar">
                        <img src={props.authorImage} alt=""/>
                    </div>
                </div>
            </Link>

            <div className="author">
                <span>Author</span>
                {props.author}
            </div>

            <div className="date">
                <span>Date</span>
                {props.date}
            </div>

            <div className="divider"></div>
        </div>        
    )
}

export default AuthorSection