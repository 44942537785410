import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, Navigate, RouterProvider, } from "react-router-dom";
import App from './App';
import About from './components/About';
import BlogPost from './components/BlogPost';
import ErrorPage from './components/ErrorPage';
import Home from './components/Home';
import Contact from './components/Contact'
import BlogHome from './components/BlogHome'
import reportWebVitals from './reportWebVitals';
import Privacy from './components/Privacy';
import BlogTopicHome from './components/BlogTopicHome';
import Courses from './components/Courses';
import './index.css';
import './css/code.css';
import './css/startup-materialize.min.css';

const blogTypes = ['AWS','JAVA'];
export default blogTypes;

async function getMainBlogSectionData(params, request){
  const url = new URL(request.url);
  const endDate = url.searchParams.get("endDate");
  const endName = url.searchParams.get("endName");
  if(endDate && endName){
    return fetch(`https://dhwrrr5a8b.execute-api.us-east-1.amazonaws.com/blog-posts?articleType=${params.baseName}&endName=${endName}&endDate=${endDate}`);
  }else{
    return fetch(`https://dhwrrr5a8b.execute-api.us-east-1.amazonaws.com/blog-posts?articleType=${params.baseName}`);
  }
}

async function getHomeSectionData(params, request){

  let promises = [];
  blogTypes.forEach(blogType => {
    promises.push(fetch(`https://dhwrrr5a8b.execute-api.us-east-1.amazonaws.com/blog-posts?articleType=${blogType}`))
  });  

  return Promise.all(promises).then(function (responses) {
    // Get a JSON object from each of the responses
    return Promise.all(responses.map(function (response) {
      return response.json();
    }));
  }).then(function (data) {
    // Log the data to the console
    // You would do something with both sets of data here
    let blogData = [];
    data.forEach(blogItem => {
      blogData.push(blogItem);
    });
    // console.log(blogData);
    return blogData;
  }).catch(function (error) {
    // if there's an error, log it
    console.log(error);
  });
  
}


//TODO reconfigure so that / is home
const router = createBrowserRouter([
  {
    path: "",
    element: <Navigate to ="/Home" replace />
  },
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <Navigate to ="/Home" replace />
      },  
      {
        path: "/Home",
        element: <Home imageLink='assets/images/javadeveloper-image-java.png' />,
        loader: async ({ params, request }) => {
          return getHomeSectionData(params, request);
        }        
      },       
      {
        path: "/About/1",
        element: <About />
      },      
      {
        path: "/Contact",
        element: <Contact />
      },        
      {
        path: "/Blog",
        element: <BlogHome />
      },  
      {
        path: "/Privacy",
        element: <Privacy />
      }, 
      {
        path: "/Courses",
        element: <Courses />
      },       
      {
        path: "/Blog/:baseName",
        element: <BlogTopicHome imageLink='assets/images/full-logo-board.png'/>,
        loader: async ({ params, request }) => {
          return getMainBlogSectionData(params, request);
        }
      },                                        
      {
        path: "/Blog/:baseName/:postName",
        element: <BlogPost />
      },          
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
