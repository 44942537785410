import React from "react";
import { Link } from "react-router-dom";

export default function BlogSnippet(props){

    // console.log(props);

    const bodyData = JSON.parse(props.blogData.body);

    // console.log(bodyData);

    let blogPosts = [];

    bodyData.Items.forEach((item, idx, arr) => {
        //create blog post snippet
        let str = item.POST_DATA
        let doc = new DOMParser().parseFromString(str, 'text/html')
        let para = doc.querySelector('p').textContent
        para = para.trim();
        para = para.slice(0,120);
        para = para + '...'
        item.POST_DATA = para;

        //modify blog post date
        let date = new Date(parseInt(item.ARTICLE_DATE * 1000))
        item.ARTICLE_DATE =  date.toDateString().slice(date.toDateString().indexOf(" ") + 1);      

        //create article link
        let link = `/Blog/${item.ARTICLE_TYPE}/${item.ARTICLE_NAME}`
        item.ARTICLE_LINK = link

        //add image to 2nd item
        if(idx === 1){
            item.IMAGE_LINK = props.imageLink;
        }

        blogPosts.push(item);
    });    

    let listItems = [];
    listItems = blogPosts.map(post =>
        <div key={post.ARTICLE_LINK}>
            <h4 ><Link to={post.ARTICLE_LINK}>{post.ARTICLE_TITLE}</Link></h4><span className="date">{post.ARTICLE_DATE}</span>
            <p className="grey-text text-lighten-1">{post.POST_DATA}</p>

            <div className="divider"></div>       

            {post.IMAGE_LINK ? <><img className="materialboxed" id="image-home" src={post.IMAGE_LINK} alt="" /> <div className="divider"></div> </> : ''} 
        </div>
    );    

    return (
        
        <>
            {listItems}
        </>
        
    )
}