import React, {useEffect} from "react";

function About(){

    useEffect(() => {
        document.title = 'About'
        window.scrollTo(0, 0)
    }, []);    
    
    
    return(
        <>
            <div>
                <div className="section header">
                    <div className="background">
                        <img src="assets/images/codebackground.jpg" alt="" />
                    </div>
                    <div className="header-wrapper row valign-wrapper">
                        <div className="col s12 m6 offset-m3 valign">
                            {/* <img className="buffer-image" src="assets/images/full_logo_buffer.png" alt="" /> */}
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <span className="tagline">                    <span>
                                <span style={{ color: "#50fa7b" }}>max</span>
                                <span style={{ color: "#8be9fd" }} >(</span>
                                <span style={{ color: "#f8f8f2" }} >devSkills</span>
                                <span style={{ color: "#8be9fd" }} >)</span>
                                <span style={{ color: "#f8f8f2" }} >;</span>
                            </span> is an informational blog for software developers
                                <br></br>
                                at all experience levels. We want to help you build marketable
                                <br></br>
                                skills and share our knowledge with you.
                            </span>
                        </div>
                    </div>
                </div>

                <div className="section full-height" id="rob-lansing">
                    <div className="row valign">
                        <div className="col s12 m10 offset-m1 team">

                            <div className="row"  >

                                <div className="col s12 l9">
                                    <div className="row">

                                    </div>
                                </div>
                            </div>

                            <div className="row">

                                <div className="col s12 m4">
                                    <div className="avatar-wrapper">
                                        <span className="avatar">
                                            <img src="assets/images/photo.PNG" alt="" />
                                        </span>
                                    </div>
                                    <div className="avatar-name">
                                        <span className="name">Rob Lansing</span>
                                        <span className="title">Founder</span>
                                    </div>
                                </div>


                                <div className="col s12 m8">
                                    <p className="text-description">
                                        Rob is a full stack software developer. He works primarily with Java/Spring based web applications. He has experience working the full spectrum of
                                        development including front-end, back-end, database, system admin, and architecture. He has worked as a lead developer doing full stack development on
                                        Spring based applications hosted in AWS. He has a passion for building highly available and durable systems in the cloud, and helping others to do the same.
                                        He holds a certification as an AWS Certified Solutions Architect - Associate. All views expressed are personal opinion and are not representative of any other organizations.

                                        <br></br><br></br>
                                        <img className="materialboxed" src="assets/images/aws-certified-solutions-architect-associate.png" alt="" />
                                        <br></br>
                                        <br></br>
                                        DISCLAIMER:
                                        All trademarks and registered trademarks appearing on maxdevskills.com are the property of their respective owners. Java is a trademark or registered trademark of Oracle Corporation in the United States and other countries. maxdevskills.com is not connected to Oracle Corporation and is not sponsored by Oracle Corporation.
                                    </p>


                                </div>
                            </div>
                            <div className="row">
                                <div className="col s12">
                                    <div className="divider"></div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
        
           
    )
}

export default About