import React from "react";
import BlogTitle from "./BlogTitle";
import AuthorSection from "./AuthorSection";
import { useNavigate } from "react-router-dom";
import hljs from "highlight.js";
import java from "highlight.js/lib/languages/java"
import javajs from "highlight.js/lib/languages/java.js"
import '../css/dracula.css';
import { useEffect } from "react";

function PostBody(props){    
    
    useEffect(() => {
        hljs.registerLanguage('java', java);
        hljs.highlightAll();
    });    
    
    const navigate = useNavigate();

    //handles clicking of regular links and sends to react router instead
    //This avoids having the browser send another request it can just be handled by react router
    //React Router uses <link> instead of <a>
    const handleClick = async (e) => {
        const targetLink = e.target.closest('a');
        if(!targetLink){
            return;
        }else{
            const location = e.target.attributes[0].value
            if(location !== '_blank'){
                e.preventDefault();
                navigate(location);                
            }

        }                        
    };      

    return(

        <div>
            <BlogTitle title={props.title} date={props.date}/>

            <div className="section full-height" id="content-section">
                <div className="row valign">
                    <div className="col s12 m6 offset-m3 blog-body">                        
                        <div onClick={handleClick} dangerouslySetInnerHTML={{__html: props.postBody}} />                    
                    </div>

                    <AuthorSection date={props.date} author={props.author} authorImage={props.authorImage}/>                    
                </div>
            </div>        
        </div>

    )
}


export default PostBody