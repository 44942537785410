import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PostBody from "./PostBody";


function BlogPost() {

    const [postData, setPostData] = useState(null); 
    const { postName } = useParams() //used for querying what post we need to see    

    useEffect(() => {
        
        fetch(`https://dhwrrr5a8b.execute-api.us-east-1.amazonaws.com/blog-posts?articleName=${postName}`, {
            method: "GET"
        })
        .then((response) => response.json())
        .then((data) => {
            var items = JSON.parse(data.body);
            var responseData = items.Items[0];
            var date = new Date(parseInt(responseData.ARTICLE_DATE * 1000))
            responseData.ARTICLE_DATE =  date.toDateString().slice(date.toDateString().indexOf(" ") + 1);
            document.title = responseData.ARTICLE_TITLE
            window.scrollTo(0, 0)
            setPostData(responseData)                

        })
        .catch((error) => console.log(error));          
                  
    }, [postName]); //use effect will listen for change on this to rerender if another blog post link is clicked       
  
    return (
      <>
      <div>
        {postData && <PostBody title={postData.ARTICLE_TITLE} date={postData.ARTICLE_DATE} author={postData.AUTHOR} authorImage={postData.AUTHOR_IMAGE} postBody={postData.POST_DATA} articleType={postData.ARTICLE_TYPE} articleName={postData.ARTICLE_NAME}/> }
      </div>                     
      </>  
    );
  }
  
  export default BlogPost;