import React, {useEffect} from "react";
import { Link, useSearchParams, useLoaderData, useNavigate, useParams } from "react-router-dom";
import BlogSnippet from "./BlogSnippet";

function NavigationButton({onClick, children}){
    return (<li className="waves-effect"><Link to="" onClick={onClick}> {children} </Link></li>)
}

export default function BlogTopicHome(props){ 
    const blogData = useLoaderData();
    const navigate = useNavigate();
    const bodyData = JSON.parse(blogData.body);

    const [searchParams] = useSearchParams();
    const articleName = searchParams.get('endName');
    const articleDate = searchParams.get('endDate');
    
    const handleForwardNav = (e) => {
        e.preventDefault();
        navigate(`?endName=${bodyData.LastEvaluatedKey.ARTICLE_NAME}&endDate=${bodyData.LastEvaluatedKey.ARTICLE_DATE}`);
    }; 

    const handleBackNav = (e) => {
        e.preventDefault();
        navigate(-1);
    };     
    
    const { baseName } = useParams();
    useEffect(() => {
        document.title = "Courses"
        window.scrollTo(0, 0)
        //Make sure page scrolls to top
        window.scrollTo({
            top: 0,
            behavior: "instant",
          });
    }, []);   

    return (
        <>
            <div className="section full-height">
                <div className="row valign">
                    <div className="col m6 offset-m3">
                        <h2 className="center">{baseName} Blog Posts</h2>
                    </div>
                </div>
                <div className="row valign">
                    <div className="col s12 m6 offset-m3 blog-body">                    
                        <BlogSnippet blogData={blogData} imageLink={props.imageLink}/>
                    </div>
                </div>            
                

                <ul className="pagination center-align">
                    {articleName && articleDate && <NavigationButton  onClick={handleBackNav}><i className="material-icons">chevron_left</i></NavigationButton>}
                    {articleName && articleDate && <NavigationButton onClick={handleBackNav}>PREVIOUS</NavigationButton>}
                    {bodyData.LastEvaluatedKey && <NavigationButton onClick={handleForwardNav}>NEXT</NavigationButton>}
                    {bodyData.LastEvaluatedKey && <NavigationButton onClick={handleForwardNav}><i className="material-icons">chevron_right</i></NavigationButton>}
                </ul>                     
            </div>
       
        </>        
    )
}