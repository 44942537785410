import React from "react";

import { Outlet } from "react-router-dom";
import Footer from "./components/Footer";
import MainNavBar from "./components/MainNavBar";

function App() {
  return (
    <> 
      <MainNavBar  />
      <Outlet />
      <Footer />      
    </>
  )
}

export default App;
