import React from "react";
import { Link } from "react-router-dom";

function Footer(){
    return (    
        <footer className="page-footer grey darken-4">
            <div className="container">
                <div className="row">
                    {/* <div className="col s6 m6">
                        <img id="image-logo" src={full_logo_buffer} alt=""/>
                    </div> */}
                    <div className="col s3 m3">
                        <h5 style={{color: "white"}}>About</h5>
                        <ul>
                            <li><Link to="/Home">Home</Link></li>
                            <li><Link to="/Courses">Courses</Link></li>
                            <li><Link to="/Privacy">Privacy</Link></li>
                        </ul>
                    </div>
                    <div className="col s3 m3">
                        {/* <h5>Contact</h5>
                        <ul>
                            <li><Link to="#!">Twitter</Link></li>
                            <li><Link to="#!">Facebook</Link></li>
                            <li><Link to="#!">Github</Link></li>
                        </ul> */}
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer