import React, {useEffect} from "react";

function Contact(){

    useEffect(() => {
        document.title = 'Contact'
        window.scrollTo(0, 0)
    }, []);    
        
    return(
        <>
            <div className="section full-height">
                <div className="row valign">
                    <div className="col s12 m10 offset-m1 team">

                        <div className="section light valign-wrapper contact-us-container">
                            <div className="container">
                                <form>
                                    <div className="row">
                                        <div className="col s12"><h2 className="section-title">Contact Us</h2></div>
                                        <div className="input-field col s6">
                                            <input id="name" type="text" />
                                            <label>Name</label>
                                        </div>
                                        <div className="input-field col s6">
                                            <input id="email" type="text" />
                                            <label >Email</label>
                                        </div>
                                        <div className="input-field col s12">
                                            <textarea id="message" className="materialize-textarea"></textarea>
                                            <label >Message</label>
                                            <button className="waves-effect waves-light btn-large">Submit</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
       
    )

}

export default Contact