import React, {useEffect} from "react";
import { Link } from "react-router-dom";

function BlogHome(){

    useEffect(() => {
        document.title = 'Blog'
        window.scrollTo(0, 0)
    }, []);    

    return(

        <div className="section full-height">
        <div className="row valign">
            <div className="col s12 m6 offset-m3 blog-body">
                <div className="col s10 offset-s1 m8 offset-m2 blog-related">
                    <h4><b>Check Out Our Blog Topics Below</b></h4>

                    <ul>
                        <li className="center">
                            <Link to="/Blog/AWS">
                                <span className="title" style={{marginLeft: 0}}>Amazon Web Services (AWS)</span>
                            </Link>
                        </li>

                        <li className="center">
                            <Link to="/Blog/JAVA">
                                <span className="title" style={{marginLeft: 0}}>Full Stack Java/Spring Software Engineering</span>
                            </Link>
                        </li>

                    </ul>
                </div>
                
            </div>
            <div className="col s12 m6 offset-m3 blog-body">
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <img className="materialboxed" id="image-home" src="assets/images/javadeveloper-image-java.png" alt="" />
            </div>            
            
        </div>
    </div>

   
    )
}

export default BlogHome