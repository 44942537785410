import M from 'materialize-css';
import { useEffect, useRef } from 'react';
import { Link, useLocation } from "react-router-dom";

function MainNavigationItem({name, path, href}){
    if(path.startsWith(`/${name}`)){
        return <li><Link className="active" to={href}>{name}</Link></li>
    }else{
        return <li><Link to={href}>{name}</Link></li>
    }
}

function MobileNavigationItem({name, path, href}){
    if(path.startsWith(`/${name}`)){
        return <li><Link className="waves-effect waves-teal active" to={href}>{name}</Link></li>
    }else{
        return <li><Link className="waves-effect waves-teal" to={href}>{name}</Link></li>
    }
}    


function MainNavBar(){

    let location = useLocation();
    const sideNavRef = useRef(null);

    useEffect(() => {
        //This is used to escape the react paradigm (it's an escape hatch to the actual dom)
        //We have to manually do this in order for the sidenav to work
        let sidenav = document.querySelectorAll('.sidenav');
        M.Sidenav.init(sidenav);
    }, []);  
    
    return(
        <>
            <nav className="navbar dark solid" id="nav">
                <div className="nav-wrapper">
                    <Link href="" className="brand-logo">
                        {/* <img src={icon_transparent_small} className="nav-image" alt=""/> */}
                        <span>
                            <span style={{color: "#50fa7b"}}>max</span>
                            <span style={{color: "#8be9fd"}} >(</span>
                            <span style={{color: "#f8f8f2"}} >devSkills</span>
                            <span style={{color: "#8be9fd"}} >)</span>
                            <span style={{color: "#f8f8f2"}} >;</span>
                        </span>
                    </Link>
                    <ul id="nav-mobile" className="right hide-on-med-and-down">
                        <MainNavigationItem name="Home" path={location.pathname} href={"/"}/>
                        <MainNavigationItem name="Courses" path={location.pathname} href={"/Courses"}/>
                        <MainNavigationItem name="Blog" path={location.pathname} href={"/Blog"}/>
                        <MainNavigationItem name="Contact" path={location.pathname} href={"/Contact"}/>                    
                    </ul>
                    
                    <button href="#" data-target="slide-out" className="sidenav-trigger sidenav-close right hide-on-med-and-up"><i className="material-icons black-text">menu</i></button>
                                      
                </div>            
            </nav>

            <ul id="slide-out" ref={sideNavRef} className="sidenav sidenav-close" >
                <MobileNavigationItem name="Home" path={location.pathname} href={"/Home"}/>
                <MobileNavigationItem name="Courses" path={location.pathname} href={"/Courses"}/>
                <MobileNavigationItem name="Blog" path={location.pathname} href={"/Blog"}/>
                <MobileNavigationItem name="Contact" path={location.pathname} href={"/Contact"}/>    
            </ul> 
        </>

               
    )
}

export default MainNavBar